import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faInstagram, faTiktok, faDiscord, faTelegram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import AnchorLink from 'react-anchor-link-smooth-scroll';


const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-nav">
                <AnchorLink href="#buy" className="footer-link">How to buy?</AnchorLink>
                <AnchorLink href="#tokenomics" className="footer-link">Tokenomics</AnchorLink>
                <AnchorLink href="#roadmap" className="footer-link">Roadmap</AnchorLink>
                <AnchorLink href="#faq" className="footer-link">FAQ</AnchorLink>
                <a className="footer-link" href="https://whitepaper.avaethernity.com/" target='_blank'>Whitepaper</a>

            </div>
            <div className="footer-social">
                <a href="https://t.me/ethernitygame" className="footer-link" target="_blank">
                    <FontAwesomeIcon icon={faTelegram} size="2x" />
                </a>
                <a href="https://twitter.com/0xETHERNITY" className="footer-link" target="_blank">
                    <FontAwesomeIcon icon={faXTwitter} size="2x" />
                </a>
                <a href="https://www.instagram.com/avaethernity" className="footer-link" target="_blank">
                    <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>
                <a href="https://www.tiktok.com/@avaethernity" className="footer-link" target="_blank">
                    <FontAwesomeIcon icon={faTiktok} size="2x" />
                </a>
                <a href="https://discord.gg/5ze32SFmmS" className="footer-link" target="_blank">
                    <FontAwesomeIcon icon={faDiscord} size="2x" />
                </a>
                <a href="https://youtube.com/@ethernitygame" className="footer-link" target="_blank">
                    <FontAwesomeIcon icon={faYoutube} size="2x" />  
                </a>
            </div>
            <p className="secret-code glow-text">Secret Code: e6367d81</p>
            <div className="footer-disclaimer">
                <h4>Disclaimer</h4>
                <p>
                    $AVAE is a meme-based cryptocurrency that lacks inherent value. It carries a high risk of capital loss, and there are no guaranteed returns. The project is entirely community-driven with no official team, and it is designed purely for entertainment. Investing in cryptocurrencies is risky and can lead to the total loss of your investment. Always conduct your own research and invest only what you can afford to lose.
                </p>
            </div>
            <div className="footer-legal">
                <a href="https://whitepaper.avaethernity.com/legal/terms-of-service" className="footer-link">Terms of Service</a>
                <a href="https://whitepaper.avaethernity.com/legal/privacy-policy" className="footer-link">Privacy Policy</a>
                <p>AVA ETHERNITY © 2024. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;

import { useState } from 'react';
import { useWertWidget } from '@wert-io/module-react-component';

export default function WertWidgetButton({ address }) {
    const options = {
        "partner_id": "01J492QC6XD5H3DK434MT0CN3P",
        "origin": "https://widget.wert.io",
        "commodity": "ETH",
        "network": "ethereum",
        "extra": {
            "wallets": [
                {
                    "name": "ETH",
                    "network": "ethereum",
                    "address": address
                },
                {
                    "name": "USDT",
                    "network": "ethereum",
                    "address": address
                },
                {
                    "name": "USDC",
                    "network": "ethereum",
                    "address": address
                },
                {
                    "name": "ETH",
                    "network": "arbitrum",
                    "address": address
                },
                {
                    "name": "USDT",
                    "network": "arbitrum",
                    "address": address
                },
                {
                    "name": "USDC",
                    "network": "arbitrum",
                    "address": address
                },
                {
                    "name": "MATIC",
                    "network": "polygon",
                    "address": address
                },
                {
                    "name": "USDT",
                    "network": "polygon",
                    "address": address
                },
                {
                    "name": "USDC",
                    "network": "polygon",
                    "address": address
                },
                {
                    "name": "ETH",
                    "network": "base",
                    "address": address
                },
                {
                    "name": "USDC",
                    "network": "base",
                    "address": address
                },
                {
                    "name": "BNB",
                    "network": "bsc",
                    "address": address
                },
                {
                    "name": "USDT",
                    "network": "bsc",
                    "address": address
                },
                {
                    "name": "USDC",
                    "network": "bsc",
                    "address": address
                },
            ]
        },
        "commodities": JSON.stringify([
            { "commodity": "ETH", "network": "ethereum" },
            { "commodity": "USDT", "network": "ethereum" },
            { "commodity": "USDC", "network": "ethereum" },
            { "commodity": "ETH", "network": "arbitrum" },
            { "commodity": "USDT", "network": "arbitrum" },
            { "commodity": "USDC", "network": "arbitrum" },
            { "commodity": "MATIC", "network": "polygon" },
            { "commodity": "USDT", "network": "polygon" },
            { "commodity": "USDC", "network": "polygon" },
            { "commodity": "ETH", "network": "base" },
            { "commodity": "USDC", "network": "base" },
            { "commodity": "BNB", "network": "bsc" },
            { "commodity": "USDT", "network": "bsc" },
            { "commodity": "USDC", "network": "bsc" },
        ])
    };

    const [reactiveOptions, setReactiveOptions] = useState({
        theme: 'light',
        listeners: {
            'loaded': () => console.log('loaded'),
        },
    });

    const { open: openWertWidget, isWidgetOpen } = useWertWidget(reactiveOptions);

    return (
        <p className="buy-card" onClick={() => {
            openWertWidget({ options });
            console.log(isWidgetOpen);
        }}>Not enough crypto? Buy with card now!</p>
    );
}

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareXTwitter, faInstagram, faTiktok, faDiscord, faTelegram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as Logo } from './logo.svg';
import {Container} from 'react-grid-system';
import AnchorLink from 'react-anchor-link-smooth-scroll';




const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className={`header ${isScrolled ? 'scrolled' : ''} ${isOpen ? 'open' : ''}`}>
            <Container className="container-max-width header-container position-static">
                <div className="logo"><Logo className="logo-icon" /></div>
                <nav className={`menu ${isOpen ? 'open' : ''}`}>
                    <ul>
                        <li><AnchorLink href="#buy" onClick={closeMenu}>How to buy?</AnchorLink></li>
                        <li><AnchorLink href="#tokenomics" onClick={closeMenu}>Tokenomics</AnchorLink></li>
                        <li><AnchorLink href="#roadmap" onClick={closeMenu}>Roadmap</AnchorLink></li>
                        <li><AnchorLink href="#faq" onClick={closeMenu}>FAQ</AnchorLink></li>
                        <li><a href="https://whitepaper.avaethernity.com/" target='_blank' onClick={closeMenu}>Whitepaper</a></li>

                    </ul>

                    <div className="mobile-social-icons">
                        <a href="https://t.me/ethernitygame" target="_blank"><FontAwesomeIcon icon={faTelegram} size="lg" /></a>
                        <a href="https://twitter.com/0xETHERNITY" target="_blank"><FontAwesomeIcon icon={faSquareXTwitter} size="lg" /></a>
                        <a href="https://www.instagram.com/avaethernity" target="_blank"><FontAwesomeIcon icon={faInstagram} size="lg" /></a>
                        <a href="https://www.tiktok.com/@avaethernity" target="_blank"><FontAwesomeIcon icon={faTiktok} size="lg" /></a>
                        <a href="https://discord.gg/5ze32SFmmS" target="_blank"><FontAwesomeIcon icon={faDiscord} size="lg" /></a>
                        <a href="https://youtube.com/@ethernitygame" target="_blank"><FontAwesomeIcon icon={faYoutube} size="lg" /></a>

                    </div>

                </nav>
                <div className="social-icons">
                    <a href="https://t.me/ethernitygame" target="_blank"><FontAwesomeIcon icon={faTelegram} size="2x" /></a>
                    <a href="https://twitter.com/0xETHERNITY" target="_blank"><FontAwesomeIcon icon={faSquareXTwitter} size="2x" /></a>
                    <a href="https://www.instagram.com/avaethernity" target="_blank"><FontAwesomeIcon icon={faInstagram} size="2x" /></a>
                    <a href="https://www.tiktok.com/@avaethernity" target="_blank"><FontAwesomeIcon icon={faTiktok} size="2x" /></a>
                    <a href="https://discord.gg/5ze32SFmmS" target="_blank"><FontAwesomeIcon icon={faDiscord} size="2x" /></a>
                    <a href="https://youtube.com/@ethernitygame" target="_blank"><FontAwesomeIcon icon={faYoutube} size="2x" /></a>

                </div>
                <div className="burger-menu" onClick={toggleMenu}>
                    <FontAwesomeIcon icon={faBars} size="lg" />
                </div>
            </Container>
        </header>
    );

};

export default Header;

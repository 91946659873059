import React, { useState, useEffect } from 'react';
import './CustomSelect.css';
import { ReactComponent as DownIcon } from './icons/chevron-down-solid.svg';

const EthIcon = require('./icons/eth.png');
const UsdtIcon = require('./icons/usdt.png');
const UsdcIcon = require('./icons/usdc.png');
const DaiIcon = require('./icons/dai.png');

const ArbitrumIcon = require('./icons/networks/arbitrum.png');
const EthereumIcon = require('./icons/networks/ethereum.png');
const PolygonIcon = require('./icons/networks/polygon.png');
const BaseIcon = require('./icons/networks/base.png');
const OptimismIcon = require('./icons/networks/optimism.png');
const SepoliaIcon = require('./icons/networks/sepolia.png');
const BaseSepoliaIcon = require('./icons/networks/baseSepolia.png');
const BnbIcon = require('./icons/networks/bnb.png');
const AvalancheIcon = require('./icons/networks/avalanche.png');

const options = [
    { value: '1', label: 'ETH', chainId: 1, name: 'Ethereum', icon: <img src={EthereumIcon} alt="Ethereum" className='network-icon' /> },
    { value: '2', label: 'ETH', chainId: 42161, name: 'Ethereum', icon: <img src={ArbitrumIcon} alt="Arbitrum One" className='network-icon' /> },
    { value: '3', label: 'ETH', chainId: 137, name: 'Polygon', icon: <img src={PolygonIcon} alt="Polygon" className='network-icon' /> },
    { value: '4', label: 'ETH', chainId: 8453, name: 'Ethereum', icon: <img src={BaseIcon} alt="Base" className='network-icon' /> },
    { value: '5', label: 'ETH', chainId: 10, name: 'Ethereum', icon: <img src={OptimismIcon} alt="OP Mainnet" className='network-icon' /> },
    { value: '6', label: 'ETH', chainId: 56, name: 'BSC', icon: <img src={BnbIcon} alt="BSC" className='network-icon' /> },
    { value: '7', label: 'ETH', chainId: 43114, name: 'Avalanche', icon: <img src={AvalancheIcon} alt="Avalanche" className='network-icon' /> },
    { value: '8', label: 'ETH', chainId: 11155111, name: 'Ethereum', icon: <img src={SepoliaIcon} alt="Sepolia" className='network-icon' /> },
    { value: '9', label: 'ETH', chainId: 84532, name: 'Ethereum', icon: <img src={BaseSepoliaIcon} alt="Base Sepolia" className='network-icon' /> },
    { value: '10', label: 'USDT', icon: <img src={UsdtIcon} alt="USDT" className='currency-icon' /> },
    { value: '11', label: 'USDC', icon: <img src={UsdcIcon} alt="USDC" className='currency-icon' /> },
    { value: '12', label: 'DAI', icon: <img src={DaiIcon} alt="DAI" className='currency-icon' /> },
];

const networkOptions = [
    { value: '1', label: 'Ethereum', icon: <img src={EthereumIcon} alt="Ethereum" className='network-icon' />, chainId: 1 },
    { value: '2', label: 'Arbitrum One', icon: <img src={ArbitrumIcon} alt="Arbitrum One" className='network-icon' />, chainId: 42161 },
    { value: '3', label: 'Polygon', icon: <img src={PolygonIcon} alt="Polygon" className='network-icon' />, chainId: 137 },
    { value: '4', label: 'Base', icon: <img src={BaseIcon} alt="Base" className='network-icon' />, chainId: 8453 },
    { value: '5', label: 'OP Mainnet', icon: <img src={OptimismIcon} alt="OP Mainnet" className='network-icon' />, chainId: 10 },
    { value: '6', label: 'BSC', icon: <img src={BnbIcon} alt="BSC" className='network-icon' />, chainId: 56 },
    { value: '7', label: 'Avalanche', icon: <img src={AvalancheIcon} alt="Avalanche" className='network-icon' />, chainId: 43114 },
    // { value: '8', label: 'Sepolia', icon: <img src={SepoliaIcon} alt="Sepolia" className='network-icon' />, chainId: 11155111 },
    // { value: '9', label: 'Base Sepolia', icon: <img src={BaseSepoliaIcon} alt="Base Sepolia" className='network-icon' />, chainId: 84532 },
];

const CustomSelect = ({ value, label, onChange, initialOption, isNetwork, selectedChain }) => {
    const ethOption = options.find(option => option.label === 'ETH' && option.chainId === selectedChain);
    const additionalOptions = options.filter(option =>
        option.label !== 'ETH' &&
        !((selectedChain === 8453 || selectedChain === 84532) && (option.label === 'USDT' || option.label === 'DAI')) &&
        !(selectedChain === 1 && option.label === 'USDT') 

    );


    const optionsList = isNetwork ? networkOptions : (ethOption ? [ethOption, ...additionalOptions] : additionalOptions);

    const [selectedOption, setSelectedOption] = useState(
        optionsList.find(option => option.label === initialOption)
    );
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const option = optionsList.find(opt => opt.label === label);
        if (option) {
            setSelectedOption(option);
        }
    }, [value, label, optionsList]);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        onChange(option);
        setIsOpen(false);
    };

    return (
        <div className="custom-select-container">
            <div
                className={`custom-select ${isOpen ? 'open' : ''}`}
                onClick={() => setIsOpen(!isOpen)}
            >
                {selectedOption.icon}
                {selectedOption.name || selectedOption.label}
                <span className={`arrow ${isOpen ? 'open' : ''}`}>
                    <DownIcon className="down-icon" />
                </span>
            </div>
            {isOpen && (
                <div className="custom-select-options">
                    {optionsList
                        .filter(option => option.value !== selectedOption.value)
                        .map((option) => (
                            <div
                                key={option.value}
                                className="custom-select-option"
                                onClick={() => handleOptionClick(option)}
                            >
                                {option.icon}
                                {option.name || option.label}
                            </div>
                        ))}
                </div>
            )}
        </div>
    );
};

export default CustomSelect;

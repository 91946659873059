import React, { useState } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { ReactComponent as DownIcon } from './icons/chevron-down-solid.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { ReactComponent as MetamaskIcon } from './metamask.svg';
import { ReactComponent as TrustWalletIcon } from './trust.svg';



import ImageListings from './landing/listings.png';
import ImagePartners from './landing/partners.png';
import ImageAwards from './landing/awards.png';

import ImageListingsMobile from './landing/listings-m.png';
import ImagePartnersMobile from './landing/partners-m.png';
import ImageAwardsMobile from './landing/awards-m.png';

import BgSection2 from './landing/bg-section-2.png';
import BgSection3 from './landing/bg-section-3.png';
import BgSection7 from './landing/bg-section-7.png';
import BgSection8 from './landing/bg-section-8.png';

import Ava1 from './landing/ava-1.png';
import Ava2 from './landing/ava-2.png';
import Ava3 from './landing/ava-3.png';
import Ava4 from './landing/ava-4.png';
import Ava5 from './landing/ava-5.png';

import MiniAva1 from './landing/mini-ava-1.png';
import MiniAva2 from './landing/mini-ava-2.png';
import MiniAva3 from './landing/mini-ava-3.png';
import MiniAva4 from './landing/mini-ava-4.png';

import backgroundGameVideoMp4 from './video/background-game.mp4';
import backgroundGameVideoWebm from './video/background-game.webm';
import backgroundGameVideoOgg from './video/background-game.ogv';

import Step1 from './landing/step-1.png';
import Step2 from './landing/step-2.png';
import Step3 from './landing/step-3.png';
import Step4 from './landing/step-4.png';

import Epic from './landing/epic.png';
import Play from './landing/play.png';

import CoinsLeft from './landing/coins-left.png';
import CoinsRight from './landing/coins-right.png';
import CoinsLeftMobile from './landing/coins-left-m.png';
import CoinsRightMobile from './landing/coins-right-m.png';
import CoinsMiddleLeftMobile from './landing/coins-middle-left-m.png';
import CoinsMiddleRightMobile from './landing/coins-middle-right-m.png';

import ReactPlayer from 'react-player';





const Landing = () => {
    const [activeIndex, setActiveIndex] = useState(0);


    const toggleAccordion = (index) => {
        setActiveIndex(prevIndex => (prevIndex === index ? null : index));
    };

    const [isVideoOverlayOpen, setIsVideoOverlayOpen] = useState(false);

    const openVideoOverlay = () => {
        setIsVideoOverlayOpen(true);
    };

    const closeVideoOverlay = (e) => {
        if (e.target.classList.contains('video-overlay')) {
            setIsVideoOverlayOpen(false);
        }
    };
    const faqData = [
        {
            question: "Who is AVA?",
            answer: (
                <p>
                    Ava is an advanced artificial intelligence created by the elite in 2052 to manage global issues such as economic collapse, corporate greed, wars, and global warming. Ava acts as the host of the ETHERNITY game, where participants compete for $AVAE.
                </p>
            )
        },
        {
            question: "Why buy $AVAE?",
            answer: (
                <p>$AVAE are integral to the ETHERNITY ecosystem, offering various benefits such as in-game rewards, staking opportunities, and participation in a community-backed project.
                </p>
            )
        },
        {
            question: "How to buy $AVAE?",
            answer: (
                <ul>
                    <li>Connect your wallet using the presale widget on our website.</li>
                    <li>Choose your preferred network.</li>
                    <li>Select the currency you wish to use for purchasing.</li>
                    <li>Enter the amount and confirm the transaction through your wallet.</li>
                </ul>
            )
        },
        {
            question: "What is a pre-sale?",
            answer: (
                <p>
                    A pre-sale is an event where you can purchase $AVAE before they become available to the general public. This offers an opportunity to acquire $AVAE at a potentially lower price, before the official launch.
                </p>
            )
        },
        {
            question: "How to receive my $AVAE?",
            answer: (
                <p>
                    After the Token Generation Event (TGE), $AVAE can be claimed through our platform. Further details and the exact timeline for claiming will be announced on our official channels.
                </p>
            )
        },
        {
            question: "How to receive airdrop?",
            answer: (
                <p>
                    Airdrops will be distributed in three phases for AVA players. NFT owners will receive additional airdrops based on the tiers and quantities of NFTs they hold.
                </p>
            )
        },
        {
            question: "How to play AVA?",
            answer: (
                <p>
                    Play AVA on Telegram by visiting <a href="https://t.me/Ava_ETHERNITY_bot" target="_blank" rel="noopener noreferrer">AVA ETHERNITY on Telegram</a> and join our social media channels for updates and community engagement.
                </p>
            )
        }
    ];


    const tokenomicsData = [
        { Name: "In-Game Rewards", Percentage: 25, Description: "A significant portion of the $AVAE supply is dedicated to rewarding players within the ETHERNITY game." },
        { Name: "Development", Percentage: 15, Description: "To compensate the development of the game, 15% of the supply is allocated to the development." },
        { Name: "Advisors", Percentage: 5, Description: "5% of the supply is allocated to advisors, who can provide valuable guidance and strategic insights." },
        { Name: "Pre-Sale", Percentage: 22, Description: "Combining the allocations for private sales, the main sale, and overflow, 22% of the supply is designated for pre-sale." },
        { Name: "Airdrop", Percentage: 8, Description: "To encourage early adoption and reward supporters, 8% of the $AVAE are reserved for airdrops." },
        { Name: "Staking", Percentage: 8, Description: "For those who stake their $AVAE, 8% of the supply is set aside to provide attractive staking rewards." },
        { Name: "Marketing", Percentage: 7, Description: "A portion of the supply is allocated for marketing efforts, helping to expand the reach and visibility of the project." },
        { Name: "Liquidity", Percentage: 10, Description: "Ensuring sufficient liquidity on various exchanges, 10% of the supply is dedicated to liquidity pools." }
    ];





    return (
        <div className="landing">
            <Container fluid className='section-1'>
                <Container>
                    <Row className='text-center'>
                        <Col xs={12} lg={12} >
                            <h2>Future listings</h2>
                            <img src={ImageListings} className='hidden-sm landing-image-listings' alt="listings" />
                            <img src={ImageListingsMobile} className='landing-image-listings-mobile visible-sm' alt="listings" />
                        </Col>
                        <Col xs={12} lg={12}>
                            <h2>Partners</h2>
                            <img src={ImagePartners} alt="partners" className='hidden-sm landing-image-partners' />
                            <img src={ImagePartnersMobile} alt="partners" className='landing-image-partners-mobile visible-sm' />
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className='section-2'>
                <Container>
                    <Row className='text-center'>
                        <Col xs={12} lg={12}>
                            <h2>Awards</h2>
                            <img src={ImageAwards} alt="awards" className='hidden-sm landing-image-awards' />
                            <img src={ImageAwardsMobile} alt="awards" className='landing-image-awards-mobile visible-sm' />
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className='section-3' id="ava">
                    <Container className='
                    container-max-width'>
                        <Row>
                            <Col xs={12} lg={5}>
                                <img src={Ava1} alt="ava-1" className='hidden-sm landing-image-ava-1' />
                            </Col>
                            <Col xs={10} push={{xs: 3, lg: 0}} lg={7} className='flex-align-center flex-center-mobile'>
                                <h2>Who is Ava?</h2>
                                <div className='ava-description-box'>
                                    <p>In 2052, the world was in turmoil due to economic and geological disasters, leaving <b>90% of the world’s population unemployed</b> and fighting for their livelihoods. A cabal of the world's richest leaders, known only as The Elite, conspired together and created ETHERNITY—a fantasy island where the world’s citizens can enter into survival competitions to earn money and glory.
                                    </p>
                                    <br />
                                    <p>ETHERNITY is hosted by the beautiful and enchanting <b>Ava</b>, who works for The Elite. She guides the players as they compete and explains the themes and rules of each game.</p>
                                </div>
                                <h4>Read the whitepaper to know the full story:</h4>
                            <a href="https://whitepaper.avaethernity.com/" className="button-link" target='blank'>
                                    <button>
                                        WHITEPAPER
                                    </button>
                                </a>
                            </Col>
                        </Row>
                    </Container>
            </Container>
            <Container fluid className='section-4'>
                <Container className="container-max-width">
                    <Row>
                        <Col xs={12} lg={7} className="flex-align-center flex-center-mobile">
                            <h2>Advantages of $AVAE</h2>
                            <Row>
                                <Col xs={12} lg={6} className="flex-center-mobile">
                                    <div className='advantages-box advantages-box-1'>
                                        <img src={MiniAva1} alt="mini-ava-1" />
                                        <div>
                                            <h3>Airdrop</h3>
                                            <p>Receive free $AVAE as an AVA player or NFT holder.</p>
                                        </div>
                                    </div>
                                    <div className='advantages-box advantages-box-3 hidden-sm'>
                                        <img src={MiniAva2} alt="mini-ava-1" />
                                        <div>
                                            <h3>Staking</h3>
                                            <p>Stake your $AVAE to earn additional rewards.</p>
                                        </div>
                                    </div>
                                    <div className='advantages-box advantages-box-alt advantages-box-3 visible-sm'>
                                        <div>
                                            <h3>Staking</h3>
                                            <p>Stake your $AVAE to earn additional rewards.</p>
                                        </div>
                                        <img src={MiniAva2} alt="mini-ava-1" />
                                    </div>
                                </Col>
                                <Col xs={12} lg={6} className="flex-center-mobile">
                                    <div className='advantages-box advantages-box-2'>
                                        <img src={MiniAva3} alt="mini-ava-1" />
                                        <div>
                                            <h3>Game</h3>
                                            <p>Play ETHERNITY game and win $AVAE rewards.</p>
                                        </div>
                                    </div>
                                    <div className='advantages-box advantages-box-4 hidden-sm'>
                                        <img src={MiniAva4} alt="mini-ava-1" />
                                        <div>
                                            <h3>Community</h3>
                                            <p>Join a community-backed project with shared vision.</p>
                                        </div>
                                    </div>
                                    <div className='advantages-box advantages-box-alt advantages-box-4 visible-sm'>
                                        <div>
                                            <h3>Community Backed</h3>
                                            <p>Join a community-backed project with shared vision.</p>
                                        </div>
                                        <img src={MiniAva4} alt="mini-ava-1" />
                                    </div>
                                </Col>

                            </Row>
                        </Col>
                        <Col xs={12} lg={5} className='flex'>
                            <img src={Ava2} alt="ava-2" className='landing-image-ava-2 hidden-sm' />
                        </Col>
                    </Row>
                </Container>
            </Container>
            <div className="video-container flex-align-center">
                <video className="background-game-video" autoPlay loop muted playsInline>
                    <source src={backgroundGameVideoWebm} type="video/webm" />
                    <source src={backgroundGameVideoOgg} type="video/ogg" />
                    <source src={backgroundGameVideoMp4} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                
                {isVideoOverlayOpen && (
                    <div className="video-overlay" onClick={closeVideoOverlay}>
                        <FontAwesomeIcon
                            className="video-overlay-close"
                            icon={faTimes}
                            size="2x"
                            color="white"
                            onClick={() => setIsVideoOverlayOpen(false)}
                        />
                        <div onClick={(e) => e.stopPropagation()}>
                            <ReactPlayer
                                className="video-overlay-player"
                                url='https://www.youtube.com/watch?v=QkMLtXndIiE'
                                controls
                                playing
                            />
                        </div>
                    </div>
                )}

                
                <div className="caption">

                    <Container className='section-5 container-max-width'>
                        <Row>
                            <Col xs={12} lg={12} className='text-center'>
                                
                              

                                <h2>What is ETHERNITY game?</h2>
                                <h4>Survive the deadly game of the elite and win $AVAE rewards.</h4>
                                <Row>
                                    <Col xs={6} lg={4} className="small-padding">
                                        <div className='game-box'>
                                            <h3>Cross-Platform
                                                Shooter:</h3>
                                            <p>Open-world gameplay featuring both PvP and PvE environment elements.</p>
                                        </div>
                                    </Col>
                                    <Col xs={6} lg={4} className="small-padding">
                                        <div className='game-box'>
                                            <h3>Realistic AI NFT Avatars:</h3>
                                            <p>Create and customize avatars using selfies and add your personality.</p>
                                        </div>
                                    </Col>
                                    <Col xs={6} lg={4} className="small-padding">
                                        <div className='game-box'>
                                            <h3>Blockchain Rewards:</h3>
                                            <p>Earn $AVAE rewards for in-game achievements and competitions.</p>
                                        </div>
                                    </Col>
                                    <Col xs={6} lg={4} className="small-padding">
                                        <div className='game-box'>
                                            <h3>AI-Driven Gameplay:</h3>
                                            <p>Advanced AI-generated dialogues with NPCs for dynamic experiences.</p>
                                        </div>
                                    </Col>
                                    <Col xs={6} lg={4} className="small-padding">
                                        <div className='game-box'>
                                            <h3>Dynamic Game Modes:</h3>
                                            <p>Game modes like story mode, battle royale, team deathmatch, deathmatch.</p>
                                        </div>
                                    </Col>
                                    <Col xs={6} lg={4} className="small-padding">
                                        <div className='game-box'>
                                            <h3>Player Economy:</h3>
                                            <p>Trade NFT skins on marketplace, create and monetize your avatars.</p>
                                        </div>
                                    </Col>


                                </Row>
                                <div className='game-buttons'>
                                    <a href="https://store.epicgames.com/en-US/p/ailand-be3b49" className="button-link" target='blank'>
                                        <button className="has-icon">ADD TO WISHLIST <div className="button-icon"><img src={Epic} /></div></button>
                                    </a>
                                    <button className="has-icon violet" onClick={openVideoOverlay}>
                                        WATCH TRAILER <div className="button-icon"><img src={Play} /></div>
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <Container fluid className='section-6' id="buy">
                <Row>
                    <Col xs={12} lg={3} className="section-6-ava-container">
                        <img src={Ava3} alt="ava-3" className='landing-image-ava-3 hidden-sm' />
                    </Col>
                    <Col xs={12} lg={6} className='flex-align-center'>
                        <Row>
                            <Col xs={12} lg={12} className='text-center'>
                                <h2>How to buy?</h2>
                                <div className='hero-buttons'>
                                    <a href='https://whitepaper.avaethernity.com/how-to-buy/trust-wallet-how-to-buy' className='btn-hero'><TrustWalletIcon className="wallet-icon" /> Trust Wallet</a>
                                    <a href='https://whitepaper.avaethernity.com/how-to-buy/metamask-how-to-buy' className='btn-hero'><MetamaskIcon className="wallet-icon" /> Metamask</a>
                                </div>
                            </Col>
                            <Col xs={12} lg={12} pull={{ lg: 0.5 }} className="flex-center-mobile">
                                <div className='step-box text-left'>
                                    <img src={Step1} alt="step-1" />
                                    <div>
                                        <h3>Connect Your Wallet</h3>
                                        <p>Navigate to the top of this page and connect your preferred wallet using the presale widget. You'll find a variety of wallets to choose from.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} lg={12} push={{ lg: 1 }} className="flex-center-mobile">
                                <div className='step-box text-left'>
                                    <img src={Step2} alt="step-2" />
                                    <div>
                                        <h3>Choose a Network</h3>
                                        <p>After connecting, you're ready to join the presale on one of several networks. $AVAE is planned to launch on the Base blockchain.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} lg={12} pull={{ lg: 0.5 }} className="flex-center-mobile">
                                <div className='step-box text-left'>
                                    <img src={Step3} alt="step-3" />
                                    <div>
                                        <h3>Select Payment Currency</h3>
                                        <p>Choose the currency you want to use to purchase $AVAE. The available currencies will depend on your selected network.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} lg={12} push={{ lg: 0.5 }} className="flex-center-mobile">
                                <div className='step-box text-left'>
                                    <img src={Step4} alt="step-4" />
                                    <div>
                                        <h3>Buy $AVAE</h3>
                                        <p>Input the amount of $AVAE you want to exchange for $AVAE and confirm the transaction through your wallet.</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={3} className="section-6-ava-container">
                        <img src={Ava4} alt="ava-4" className='landing-image-ava-4 hidden-sm' />
                    </Col>

                </Row>
            </Container>
            <Container fluid className='section-7' id="tokenomics">
                    <Container className='position-relative container-max-width'>
                    <img src={CoinsLeft} className='coins-left hidden-sm' alt="coins-left" />
                    <img src={CoinsRight} className='coins-right hidden-sm' alt="coins-right" />

                    <img src={CoinsLeftMobile} className='coins-left-mobile visible-sm' alt="coins-left" />
                    <img src={CoinsRightMobile} className='coins-right-mobile visible-sm' alt="coins-right" />
                    <img src={CoinsMiddleLeftMobile} className='coins-middle-left-mobile visible-sm' alt="coins-left" />
                    <img src={CoinsMiddleRightMobile} className='coins-middle-right-mobile visible-sm' alt="coins-right" />
                        <Row>
                            <Col xs={12} lg={12} className='text-center'>
                                <h2>Tokenomics</h2>
                                <p>The $AVAE has a total fixed supply capped at 1,000,000,000 $AVAE. This supply is strategically allocated across various segments within the AVA ETHERNITY ecosystem to support its growth and sustainability:</p>
                            </Col>
                            {tokenomicsData.map((item, index) => (
                                <Col xs={12} lg={4} key={index} className="flex-center-mobile">
                                    <div className='tokenomics-box'>
                                        <h3>{item.Name}: {item.Percentage}%</h3>
                                        <div className="tokenomics-progress-bar-container">
                                            <div className="tokenomics-progress-bar" style={{ width: `${item.Percentage}%` }}></div>
                                        </div>
                                        <p>{item.Description}</p>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Container>
            </Container>
            <Container fluid className="section-8" id="roadmap">
                    <Container className='container-max-width'>
                        <Row>
                            <Col xs={12} lg={12} className='text-center'>
                                <h2>Roadmap</h2>
                            </Col>
                        <Col xs={12} lg={4} className="flex-center-mobile">
                                <div className='roadmap-box roadmap-box-1'>
                                    <h4>✅ Stage 1</h4>
                                    <div className="roadmap-title">
                                        <h4>Genesis</h4>
                                    </div>
                                    <ol>
                                    <li>✅ Website launch</li>
                                    <li>✅ Social media estabilishment</li>
                                    <li>✅ AVA mini-game launch</li>
                                    </ol>
                                </div>
                            </Col>
                        <Col xs={12} lg={4} className="flex-center-mobile">
                                <div className='roadmap-box roadmap-box-2'>
                                <h4>✅ Stage 2</h4>
                                    <div className="roadmap-title">
                                    <h4>Expansion</h4>
                                    </div>
                                    <ol>
                                    <li>✅ Community Building</li>
                                    <li>✅ Partnerships</li>
                                    <li>✅ NFT sale</li>
                                    </ol>
                                </div>
                            </Col>
                        <Col xs={12} lg={4} className="flex-center-mobile">
                                <div className='roadmap-box roadmap-box-3'>
                                    <h4>Stage 3</h4>
                                    <div className="roadmap-title">
                                        <h4>Validation</h4>
                                    </div>
                                    <ol>
                                        <li>Pre-sale launch</li>
                                        <li>Contract Audit</li>
                                    </ol>
                                </div>
                            </Col>
                            <Col xs={12} lg={2}></Col>
                        <Col xs={12} lg={4} className="flex-center-mobile">
                                <div className='roadmap-box roadmap-box-4'>
                                    <h4>Stage 4</h4>
                                    <div className="roadmap-title">
                                        <h4>Launch</h4>
                                    </div>
                                    <ol>
                                        <li>Token Generation Event</li>
                                        <li>Airdrop</li>
                                        <li>DEX Listing</li>
                                    </ol>
                                </div>
                            </Col>
                        <Col xs={12} lg={4} className="flex-center-mobile">
                                <div className='roadmap-box roadmap-box-5'>
                                    <h4>Stage 5</h4>
                                    <div className="roadmap-title">
                                        <h4>Growth</h4>
                                    </div>
                                    <ol>
                                        <li>CEX Listing</li>
                                        <li>Staking</li>
                                        <li>ETHERNITY game launch</li>
                                    </ol>
                                </div>
                            </Col>
                            <Col xs={12} lg={2}></Col>
                        </Row>
                    </Container>
            </Container>
            <Container fluid className='section-9' id="faq">
                <Row>
                    <Col xs={12} lg={12} className='text-center'>

                    </Col>
                    <Col xs={12} lg={2}></Col>
                    <Col xs={12} lg={6} className="flex-align-center">
                        <div className="text-center full-width">
                            <h2 className="text-center">FAQ</h2>
                        </div>
                        {faqData.map((item, index) => (
                            <div className="accordion" key={index}>
                                <div className="accordion-header" onClick={() => toggleAccordion(index)}>
                                    <h3>{item.question}</h3>
                                    <div className={`accordion-arrow ${activeIndex === index ? 'open' : ''}`}>
                                        <DownIcon />
                                    </div>
                                </div>
                                <div className={`accordion-content ${activeIndex === index ? 'open' : ''}`}>
                                    <div>{item.answer}</div>
                                </div>
                            </div>
                        ))}
                    </Col>
                    <Col xs={12} lg={4} className='no-padding'>
                        <img src={Ava5} alt="ava-5" className='landing-image-ava-5 hidden-sm' />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Landing;

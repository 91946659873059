import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import backgroundVideoMp4 from './video/background.mp4';
import backgroundVideoWebm from './video/background.webm';
import backgroundVideoOgg from './video/background.ogv';
import { SendTransaction } from './SendTransaction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons';

import VideoPoster from './landing/bg-hero.jpg';



const Hero = () => {
    return (
        <Container className='full-height position-relative hero-container ' fluid>
            <div className='bg-blur'></div>
            <video className="background-video" autoPlay loop muted playsInline poster={VideoPoster}>
                <source src={backgroundVideoWebm} type="video/webm" />
                <source src={backgroundVideoOgg} type="video/ogg" />
                <source src={backgroundVideoMp4} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <Container className='container-max-width small-padding'>
                <Row>
                    <Col md={12} lg={8} xl={6} className=' hero-intro text-center-xs flex-center-mobile'>
                        <h1 className='hero-text'>Meet Ava</h1>
                        <h2 className='hero-text'>AI Girl From the Secret World Order</h2>
                        <h3>Become part of the elite — backers of  the ETHERNITY deadly game and leaders of the secret world order</h3>
                    </Col>
                    <Col md={12} lg={4} xl={6} className='hero-buy-container'>
                        <div className='hero-buy centered-content'>
                            <h2 className='hero-text'>Buy $AVAE now</h2>
                            <SendTransaction />
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default Hero;

import React, { useEffect, useRef, useState } from 'react';
import { useSendTransaction, useWaitForTransactionReceipt, useWriteContract, useSwitchChain, useChainId, useBalance, useAccount } from 'wagmi';
import { parseEther, parseUnits } from 'viem';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { tokenAbi, fundraisingAbi } from './abi';
import CustomSelect from './CustomSelect';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as DownIcon } from './icons/chevron-down-solid.svg';
import { formatUnits } from 'viem';
import WertWidgetButton from './WertWidgetButton';
import ReactGA from "react-ga4";
import MetamaskIcon from './metamask.svg';
import TrustWalletIcon from './trust.svg';




const UsdtIcon = require('./icons/usdt.png');
const UsdcIcon = require('./icons/usdc.png');
const DaiIcon = require('./icons/dai.png');
const AvaIcon = require('./icons/ava.png');
const ArbitrumIcon = require('./icons/networks/arbitrum.png');
const EthereumIcon = require('./icons/networks/ethereum.png');
const PolygonIcon = require('./icons/networks/polygon.png');
const BaseIcon = require('./icons/networks/base.png');
const OptimismIcon = require('./icons/networks/optimism.png');
const SepoliaIcon = require('./icons/networks/sepolia.png');
const BaseSepoliaIcon = require('./icons/networks/baseSepolia.png');
const BnbIcon = require('./icons/networks/bnb.png');
const AvalancheIcon = require('./icons/networks/avalanche.png');


const currencyOptions = [
    { label: 'ETH', icon: <img src={EthereumIcon} className='buy-currency-icon' />, chainId: 1 },
    { label: 'ETH', icon: <img src={ArbitrumIcon} className='buy-currency-icon' />, chainId: 42161 },
    { label: 'ETH', icon: <img src={PolygonIcon} className='buy-currency-icon' />, chainId: 137 },
    { label: 'ETH', icon: <img src={BaseIcon} className='buy-currency-icon' />, chainId: 8453 },
    { label: 'ETH', icon: <img src={OptimismIcon} className='buy-currency-icon' />, chainId: 10 },
    { label: 'ETH', icon: <img src={BnbIcon} className='buy-currency-icon' />, chainId: 56 },
    { label: 'ETH', icon: <img src={AvalancheIcon} className='buy-currency-icon' />, chainId: 43114 },
    { label: 'ETH', icon: <img src={SepoliaIcon} className='buy-currency-icon' />, chainId: 11155111 },
    { label: 'ETH', icon: <img src={BaseSepoliaIcon} className='buy-currency-icon' />, chainId: 84532 },
    { label: 'USDT', icon: <img src={UsdtIcon} className='buy-currency-icon' /> },
    { label: 'USDC', icon: <img src={UsdcIcon} className='buy-currency-icon' /> },
    { label: 'DAI', icon: <img src={DaiIcon} className='buy-currency-icon' /> },
];


// Address map based on chain ID
const addressMap = {
    // Sepolia 
    // 11155111: {
    //     contractAddress: "0xE1BD1336B1B22588a95246F71f750F9A406Aa778",
    //     tokens: {
    //         eth: { name: 'ETH', address: null },
    //         usdt: { name: 'USDT', address: "0x982576028C9Ee9cCb4CEf59491509963AB4389E6" },
    //         usdc: { name: 'USDC', address: "0xBc15a721A2D5345070528DfAdB69f3079d207479" },
    //         dai: { name: 'DAI', address: "0xD78Da25d9a7bD1625362CE7903C69EB8E9FD2DFC" },
    //     }
    // },
    // // Base Sepolia
    // 84532: {
    //     contractAddress: "0x08AEc5A7119D215670c6ba87AC6cc6aE552Bd99A",
    //     tokens: {
    //         eth: { name: 'ETH', address: null },
    //         usdc: { name: 'USDC', address: "0x5135f0e2689b17Ca0DEa1f5de770571B7ff94e4A" },
    //     }
    // },
    // Ethereum
    1: {
        contractAddress: "0x19bc63963e902CD86BE62a3eeb47e4531e3C106e",
        tokens: {
            eth: { name: 'ETH', address: null },
            usdt: { name: 'USDT', address: "0xdac17f958d2ee523a2206206994597c13d831ec7" },
            usdc: { name: 'USDC', address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48" },
            dai: { name: 'DAI', address: "0x6b175474e89094c44da98b954eedeac495271d0f" },
        }
    },
    // Arbitrum One
    42161: {
        contractAddress: "0xAa87A23ff8Dfc29077C85510BE9d0Fa6B74bCDbE",
        tokens: {
            eth: { name: 'ETH', address: null },
            usdt: { name: 'USDT', address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9" },
            usdc: { name: 'USDC', address: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831" },
            dai: { name: 'DAI', address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1" },
        }
    },
    // Polygon
    137: {
        contractAddress: "0xAa87A23ff8Dfc29077C85510BE9d0Fa6B74bCDbE",
        tokens: {
            eth: { name: 'ETH', address: null },
            usdt: { name: 'USDT', address: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f" },
            usdc: { name: 'USDC', address: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359" },
            dai: { name: 'DAI', address: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063" },
        }
    },
    // Base
    8453: {
        contractAddress: "0xAa87A23ff8Dfc29077C85510BE9d0Fa6B74bCDbE",
        tokens: {
            eth: { name: 'ETH', address: null },
            usdc: { name: 'USDC', address: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913" },
        }
    },
    // Optimism
    10: {
        contractAddress: "0xAa87A23ff8Dfc29077C85510BE9d0Fa6B74bCDbE",
        tokens: {
            eth: { name: 'ETH', address: null },
            usdt: { name: 'USDT', address: "0x94b008aa00579c1307b0ef2c499ad98a8ce58e58" },
            usdc: { name: 'USDC', address: "0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85" },
            dai: { name: 'DAI', address: "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1" },
        }
    },
    // BSC
    56: {
        contractAddress: "0xAa87A23ff8Dfc29077C85510BE9d0Fa6B74bCDbE",
        tokens: {
            eth: { name: 'ETH', address: null },
            usdt: { name: 'USDT', address: "0x55d398326f99059ff775485246999027b3197955" },
            usdc: { name: 'USDC', address: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d" },
            dai: { name: 'DAI', address: "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3" },
        }
    },
    // Avalanche
    43114: {
        contractAddress: "0xAa87A23ff8Dfc29077C85510BE9d0Fa6B74bCDbE",
        tokens: {
            eth: { name: 'ETH', address: null },
            usdt: { name: 'USDT', address: "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7" },
            usdc: { name: 'USDC', address: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E" },
            dai: { name: 'DAI', address: "0xd586E7F844cEa2F87f50152665BCbc2C279D8d70" },
        }
    },
};

const explorerMap = {
    1: 'https://etherscan.io/tx/',
    42161: 'https://arbiscan.io/tx/',
    137: 'https://polygonscan.com/tx/',
    8453: 'https://basescan.org/tx/',
    10: 'https://optimistic.etherscan.io/tx/',
    56: 'https://bscscan.com/tx/',
    43114: 'https://avascan.info/blockchain/c/tx/',
};


export function SendTransaction() {
    const { address } = useAccount(); // Get the current account

    const { chains, switchChain } = useSwitchChain();
    const currentChainId = useChainId();
    const { data: approveHash, isPending: isApprovePending, writeContract: approveToken } = useWriteContract();
    const { data: approveReceipt, isSuccess: isApproveSuccess } = useWaitForTransactionReceipt({
        hash: approveHash,
    });

    const { data: contributeHash, isPending: isContributePending, writeContract: contributeToken } = useWriteContract();
    const { data: contributeReceipt, isSuccess: isContributeSuccess } = useWaitForTransactionReceipt({
        hash: contributeHash,
    });

    const { data: ethTransactionHash, isPending: isEthPending, sendTransaction } = useSendTransaction();
    const { data: ethReceipt, isSuccess: isEthSuccess } = useWaitForTransactionReceipt({
        hash: ethTransactionHash,
    });

    const [approvedAmount, setApprovedAmount] = React.useState(null);
    const [selectedToken, setSelectedToken] = React.useState(addressMap[currentChainId]?.tokens?.eth || { name: 'ETH', address: null }); // Default to ETH
    const [selectedChain, setSelectedChain] = React.useState(1); // Set to the current chain on page load
    const [value, setValue] = React.useState(""); // State for transaction value
    const [tokensReceived, setTokensReceived] = React.useState(0);
    const [amountInUSD, setAmountInUSD] = React.useState(0.00);
    const debounceTimeout = useRef(null); // Ref to store the debounce timeout
    
    const [amountRaised, setAmountRaised] = useState(0);
    const [countdown, setCountdown] = useState(0);
    const [currentPrice, setCurrentPrice] = useState("0.0000");
    const [amountTotal, setAmountTotal] = useState(150000);
    const countdownInterval = useRef(null); // Ref to store the interval ID for the countdown
    const [purchaseData, setPurchaseData] = useState({
        sepolia: 0,
        baseSepolia: 0,
        ethereum: 0,
        optimism: 0,
        bnbChain: 0,
        polygon: 0,
        base: 0,
        arbitrum: 0,
        avalanche: 0,
    });
    const [showPurchaseDetails, setShowPurchaseDetails] = useState(false);
    const [isCalculateTokensFetching, setIsCalculateTokensFetching] = React.useState(false); // New state to track fetching status
    const [isWalletConnected, setIsWalletConnected] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(true); // Add this line
    const [showStablecoinWarning, setShowStablecoinWarning] = React.useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [referralCode, setReferralCode] = useState(null);
    const [showCopiedPopup, setShowCopiedPopup] = useState(false);
    const [referralAmount, setReferralAmount] = useState(0);
    const [referrer, setReferrer] = useState(null);









    const [isEthTxSuccess, setIsEthTxSuccess] = useState(false);
    const [isContributeTxSuccess, setIsContributeTxSuccess] = useState(false);
    const [isApproveTxSuccess, setIsApproveTxSuccess] = useState(false);
    const [lastPendingTxType, setLastPendingTxType] = useState(null); // new state for tracking last pending transaction
    const [transactionId, setTransactionId] = useState(null);


    async function fetchAmountRaised(setAmountRaised, setCountdown, setCurrentPrice, setAmountTotal) {
        try {
            const response = await axios.get('/api/round-details');
            if (response.data) {
                if (response.data.totalRaised) {
                    setAmountRaised(parseFloat(response.data.totalRaised).toFixed(0));
                }
                if (response.data.secondsUntilNextIncrease) {
                    setCountdown(response.data.secondsUntilNextIncrease);
                }
                if (response.data.currentPrice) {
                    setCurrentPrice(response.data.currentPrice);
                }
                if (response.data.currentRound) {
                    switch (response.data.currentRound) {
                        case 'private':
                            setAmountTotal(150000);
                            break;
                        case 'main':
                            setAmountTotal(400000);
                            break;
                        case 'overflow':
                            setAmountTotal(1220000);
                            break;
                        default:
                            setAmountTotal(150000);
                    }
                }
            }
            setIsLoading(false); // Add this line
        } catch (error) {
            console.error("Error fetching round details:", error);
            setIsLoading(false); // Add this line to stop loading even in case of an error
        }
    }


    useEffect(() => {
        fetchAmountRaised(setAmountRaised, setCountdown, setCurrentPrice, setAmountTotal);
    }, []);

    useEffect(() => {
        if (isEthTxSuccess || isContributeTxSuccess || isApproveTxSuccess) {
            const timer = setTimeout(() => {
                fetchAmountRaised(setAmountRaised, setCountdown, setCurrentPrice, setAmountTotal);
                fetchPurchaseData(address, setPurchaseData);
                if (isWalletConnected) {
                    refetchBalance();
                }
            }, 10000); // 10 seconds delay

            // Clean up the timer if the component unmounts or dependencies change
            return () => clearTimeout(timer);
        }
    }, [isEthTxSuccess, isContributeTxSuccess, isApproveTxSuccess]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchAmountRaised(setAmountRaised, setCountdown, setCurrentPrice, setAmountTotal);
            if (isWalletConnected) {
                refetchBalance();
            }
        }, 300000); // 300,000 milliseconds = 5 minutes

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, []);





    async function fetchPurchaseData(address, setPurchaseData) {
        try {
            if (address) {
                console.log("Fetching purchase data for address:", address);
                const response = await axios.get(`/api/user-deposits?user=${address}`);
                if (response.data) {
                    const { amountInTokensByChainId } = response.data;
                    setPurchaseData({
                        total: response.data.totalAmountInTokens || 0,
                        sepolia: amountInTokensByChainId['11155111'] || 0,
                        baseSepolia: amountInTokensByChainId['84532'] || 0,
                        ethereum: amountInTokensByChainId['1'] || 0,
                        optimism: amountInTokensByChainId['10'] || 0,
                        bnbChain: amountInTokensByChainId['56'] || 0,
                        polygon: amountInTokensByChainId['137'] || 0,
                        base: amountInTokensByChainId['8453'] || 0,
                        arbitrum: amountInTokensByChainId['42161'] || 0,
                        avalanche: amountInTokensByChainId['43114'] || 0,
                    });
                    console.log("Purchase data fetched:", response.data);
                }
            }
        } catch (error) {
            console.error("Error fetching purchase data:", error);
        }
    }

    // useEffect to call fetchPurchaseData when address changes
    useEffect(() => {
        fetchPurchaseData(address, setPurchaseData);
    }, [address]);



    useEffect(() => {
        if (countdown > 0) {
            countdownInterval.current = setInterval(() => {
                setCountdown(prev => {
                    if (prev <= 1) {
                        clearInterval(countdownInterval.current);
                        // Refetch data when countdown reaches zero
                        fetchAmountRaised(setAmountRaised, setCountdown, setCurrentPrice, setAmountTotal);
                        if (isWalletConnected) {
                            refetchBalance();
                        }
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }

        return () => {
            clearInterval(countdownInterval.current);
        };
    }, [countdown]);


    function formatCountdown(seconds) {
        const d = Math.floor(seconds / (3600 * 24));
        const h = Math.floor((seconds % (3600 * 24)) / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.floor(seconds % 60);

        return { d, h, m, s };
    }

    const { d, h, m, s } = formatCountdown(countdown);



    React.useEffect(() => {
        if (isApprovePending) setLastPendingTxType('approve');
        if (isContributePending) setLastPendingTxType('contribute');
        if (isEthPending) setLastPendingTxType('eth');

        if (isApprovePending || isContributePending || isEthPending) {
            setIsEthTxSuccess(false);
            setIsContributeTxSuccess(false);
            setIsApproveTxSuccess(false);
            console.log('Resetting transaction success states due to pending transactions');
        }
    }, [isApprovePending, isContributePending, isEthPending]);

    React.useEffect(() => {
        if (lastPendingTxType === 'approve' && isApproveSuccess) {
            setIsApproveTxSuccess(true);
        } else if (lastPendingTxType === 'contribute' && isContributeSuccess) {
            setIsContributeTxSuccess(true);
        } else if (lastPendingTxType === 'eth' && isEthSuccess) {
            setIsEthTxSuccess(true);
        }
    }, [isApproveSuccess, isContributeSuccess, isEthSuccess]);

    const sendPreTransactionDataToBackend = async (walletAddress, amount, amountInUsd, amountInTokens, chainId, currency, transactionId) => {
        try {
            const response = await axios.post('/api/pre-process-transaction', {
                walletAddress: walletAddress,
                amount: amount,
                amountInUsd: amountInUsd,
                amountInTokens: amountInTokens,
                chainId: chainId,
                currency: currency,
                transactionId: transactionId, // Include the transaction ID
                referrer: referrer // Include the referrer
            });
            console.log(response.data);
        } catch (error) {
            console.error("Error sending pre-transaction data to backend:", error);
        }
    };

    const sendTransactionDataToBackend = async (transactionHash, amount, amountInUsd, amountInTokens, chainId, currency, transactionId) => {
        try {
            const response = await axios.post('/api/process-transaction', {
                walletAddress: address,
                transactionHash: transactionHash,
                amount: amount,
                amountInUsd: amountInUsd,
                amountInTokens: amountInTokens,
                chainId: chainId,
                currency: currency,
                transactionId: transactionId, // Include the transaction ID
                referrer: referrer // Include the referrer
            });
            console.log(response.data);
        } catch (error) {
            console.error("Error sending transaction data to backend:", error);
        }
    };



    React.useEffect(() => {
        if (isEthTxSuccess) {
            setIsProcessing(false);
            sendTransactionDataToBackend(
                ethTransactionHash,
                parseFloat(value),
                parseFloat(amountInUSD),
                tokensReceived,
                selectedChain,
                'ETH',
                transactionId // Pass the transaction ID
            );
        }
    }, [isEthTxSuccess]);

    React.useEffect(() => {
        if (isContributeTxSuccess) {
            setIsProcessing(false);
            sendTransactionDataToBackend(
                contributeHash,
                parseFloat(value),
                parseFloat(amountInUSD),
                tokensReceived,
                selectedChain,
                selectedToken.name,
                transactionId // Pass the transaction ID
            );
        }
    }, [isContributeTxSuccess]);



    async function calculateTokens() {
        try {
            const response = await axios.post('/api/calculateTokens', {
                currency: selectedToken.name,
                amount: value,
                chainId: selectedChain
            });

            if (response.data) {
                if (response.data.tokens) {
                    setTokensReceived(response.data.tokens);
                }
                if (response.data.amountInUSD) {
                    const amountInUSDValue = parseFloat(response.data.amountInUSD).toFixed(2);
                    setAmountInUSD(amountInUSDValue);
                    if (amountInUSDValue < 1) {
                        setErrorMessage("Minimum purchase is $1");
                    } else if (amountInUSDValue > (amountTotal - amountRaised)) {
                        setErrorMessage(`Maximum purchase is $${(amountTotal - amountRaised).toFixed(0)}`);
                    }
                    else {
                        setErrorMessage("");
                    }
                }
            }
        } catch (error) {
            console.error("Error calculating tokens:", error);
        } finally {
            setIsCalculateTokensFetching(false);
        }
    }


    React.useEffect(() => {
        console.log("isCalculateTokensFetching:", isCalculateTokensFetching);
    }, [isCalculateTokensFetching]);



    // Debounce effect
    useEffect(() => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
            if (value && selectedToken && selectedChain) {
                calculateTokens();
            }
        }, 500);

        return () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        };
    }, [value, selectedToken, selectedChain]);


    const { data: balanceData, isLoading: isBalanceLoading, error: balanceError, refetch: refetchBalance } = useBalance({
        address, // Use the address from useAccount
        chainId: selectedChain,
        token: selectedToken.address,
        refetchInterval: 30000, // 30 seconds interval
        refetchIntervalInBackground: true, // Continue to refetch even when the tab is in the background
    });

    React.useEffect(() => {
        // Update selectedToken based on selectedChain
        const chainData = addressMap[selectedChain] || {};
        setSelectedToken(chainData.tokens ? chainData.tokens.eth : { name: 'ETH', address: null });
    }, [selectedChain]);



    async function handleTransaction() {
        if (!isWalletConnected) {
            setErrorMessage("Please connect your wallet");
            return;
        }
        if (!value) {
            setErrorMessage("Please enter a valid amount");
            return;
        }
        if (isCalculateTokensFetching || amountInUSD < 1 || amountInUSD > (amountTotal - amountRaised)) {
            console.error("Invalid amount or fetching in progress");
            return; // Prevent the function from proceeding
        }

        const chainData = addressMap[selectedChain];
        const contractAddress = chainData?.contractAddress;

        if (!contractAddress) {
            console.error("Invalid chain selected");
            return;
        }

        let gaAmount = parseFloat(amountInUSD);
        gaAmount = parseFloat(gaAmount.toFixed(2));
        // console.log("💰 Amount in USD:", gaAmount);

        ReactGA.event({
            category: "Button",
            action: "buy_token",
            label: "Buy Button",
            value: gaAmount
        });

        setIsProcessing(true);

        const newTransactionId = uuidv4();
        setTransactionId(newTransactionId);

        await sendPreTransactionDataToBackend(
            address,
            parseFloat(value),
            parseFloat(amountInUSD),
            tokensReceived,
            selectedChain,
            selectedToken.name,
            newTransactionId // Pass the transaction ID
        );

        if (selectedToken.address === null) {
            sendTransaction({ to: contractAddress, value: parseEther(value) });
        } else {
            let decimals;
            if (selectedChain === 56 && (selectedToken.name === 'USDT' || selectedToken.name === 'USDC')) {
                decimals = 18; // Use 18 decimals for USDT and USDC on BSC
            } else {
                decimals = (selectedToken.name === 'USDT' || selectedToken.name === 'USDC') ? 6 : 18;
            }
            const amount = parseUnits(value, decimals);
            setApprovedAmount(amount); // Store the approved amount in state

            approveToken({
                abi: tokenAbi,
                address: selectedToken.address,
                functionName: 'approve',
                args: [contractAddress, amount],
            });
        }
    }







    React.useEffect(() => {
        const chainData = addressMap[selectedChain];
        const contractAddress = chainData?.contractAddress;

        if (isApproveSuccess && approvedAmount !== null && contractAddress && selectedToken?.address) {
            contributeToken({
                abi: fundraisingAbi,
                address: contractAddress,
                functionName: 'contributeToken',
                args: [selectedToken.address, approvedAmount],
            });
        }
    }, [isApproveSuccess, approvedAmount, selectedToken, selectedChain, contributeToken]);


    function handleChainChange(option) {
        const chainId = option.chainId;
        console.log("Switching to chain:", chainId);
        setSelectedChain(chainId);
        switchChain({ chainId });
    }

    React.useEffect(() => {
        if (isWalletConnected) {  // Check if the wallet is connected
            setSelectedChain(currentChainId);
            console.log("Selected chain on page load:", currentChainId);  // Log the selected chain ID
        } else {
            console.log("Wallet is not connected, skipping chain selection.");
        }
    }, [currentChainId, isWalletConnected]);  // Add isWalletConnected as a dependency


    const percentageRaised = (amountRaised / amountTotal) * 100;

    const handleTogglePurchaseDetails = () => {
        setShowPurchaseDetails(prevState => !prevState);
    };

    const getTokenIcon = (tokenName, chainId) => {
        if (tokenName === 'ETH') {
            switch (chainId) {
                case 1: return EthereumIcon;
                case 42161: return ArbitrumIcon;
                case 137: return PolygonIcon;
                case 8453: return BaseIcon;
                case 10: return OptimismIcon;
                case 56: return BnbIcon;
                case 43114: return AvalancheIcon;
                case 11155111: return SepoliaIcon;
                case 84532: return BaseSepoliaIcon;
                default: return EthereumIcon;
            }
        } else {
            switch (tokenName) {
                case 'USDT': return UsdtIcon;
                case 'USDC': return UsdcIcon;
                case 'DAI': return DaiIcon;
                default: return EthereumIcon;
            }
        }
    };

    const handleMaxClick = () => {
        if (balanceData) {
            let decimals;
            if (selectedChain === 56 && (selectedToken.name === 'USDT' || selectedToken.name === 'USDC')) {
                decimals = 18; // Use 18 decimals for USDT and USDC on BSC
            } else {
                decimals = (selectedToken.name === 'USDT' || selectedToken.name === 'USDC') ? 6 : 18;
            }
            const fullAmount = parseFloat(formatUnits(balanceData.value, decimals));
            const maxAmount = (fullAmount * 0.98).toFixed((selectedToken.name === 'ETH') ? 4 : 2); // Set to 95% of the full amount
            setValue(maxAmount);
        }
    };


    const handleInputChange = (e) => {
        const inputValue = e.target.value.replace(',', '.'); // Replace comma with dot
        const sanitizedValue = inputValue.replace(/[^0-9.]/g, ''); // Allow only numbers and dot

        if (sanitizedValue === '') {
            setIsCalculateTokensFetching(false);
            setTokensReceived(0);
        } else {
            setIsCalculateTokensFetching(true);
        }

        let decimals = 18; // Default to 18 decimals
        if (selectedChain !== 56 && (selectedToken.name === 'USDT' || selectedToken.name === 'USDC')) {
            decimals = 6; // Use 6 decimals for USDT and USDC except on BSC
        }

        setValue(sanitizedValue);
    };

    async function fetchReferralCode(walletAddress) {
        try {
            const response = await axios.post('/api/referral', {
                walletAddress: walletAddress
            });
            if (response.data) {
                if (response.data.referralCode) {
                    setReferralCode(response.data.referralCode);
                }
                if (response.data.amount) {
                    setReferralAmount(response.data.amount);
                }
            }
        } catch (error) {
            console.error("Error fetching referral code:", error);
        }
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const ref = urlParams.get('ref');
        if (ref) {
            console.log("Referrer:", ref);
            setReferrer(ref);
        }
    }, []);

    const deeplinkMetamask = referrer
        ? `https://metamask.app.link/dapp/avaethernity.com/?ref=${referrer}`
        : "https://metamask.app.link/dapp/avaethernity.com";

    const deeplinkTrust = referrer
        ? `https://link.trustwallet.com/open_url?coin_id=60&url=https://avaethernity.com/?ref=${referrer}`
        : "https://link.trustwallet.com/open_url?coin_id=60&url=https://avaethernity.com";



    const handleCopyClick = () => {
        navigator.clipboard.writeText(`https://avaethernity.com/?ref=${referralCode}`);
        setShowCopiedPopup(true);
        setTimeout(() => {
            setShowCopiedPopup(false);
        }, 2000); // Hide the popup after 2 seconds
    };


    useEffect(() => {
        if (address) {
            setIsWalletConnected(true);
            if (errorMessage === "Please connect your wallet") {
                setErrorMessage("");
            }
            fetchReferralCode(address); // Fetch referral code when the wallet is connected
        } else {
            setIsWalletConnected(false);
            setReferralCode(null); // Clear referral code if wallet is disconnected
        }
    }, [address, errorMessage]);


    React.useEffect(() => {
        if (selectedToken.name === 'USDT' || selectedToken.name === 'USDC' || selectedToken.name === 'DAI') {
            setShowStablecoinWarning(true);
        } else {
            setShowStablecoinWarning(false);
        }
    }, [selectedToken]);



    return (
        <div className="buy-container">
            {isLoading && ( // Add this condition
                <div className="buy-container-loader">
                    <span className="loader"></span>
                </div>
            )}
            <h3>Token Price Increase In:</h3>
            <div className="countdown">
                <div className="countdown-time-container">
                    <div className="countdown-time">{d}</div>
                    <span className="countdown-label">Days</span>
                </div>
                <span className="countdown-separator">:</span>
                <div className="countdown-time-container">
                    <div className="countdown-time">{h}</div>
                    <span className="countdown-label">Hours</span>
                </div>
                <span className="countdown-separator">:</span>
                <div className="countdown-time-container">
                    <div className="countdown-time">{m}</div>
                    <span className="countdown-label">Minutes</span>
                </div>
                <span className="countdown-separator">:</span>
                <div className="countdown-time-container">
                    <div className="countdown-time">{s}</div>
                    <span className="countdown-label">Seconds</span>
                </div>
            </div>
            <p>USD raised: <b>${amountRaised}</b> / ${amountTotal}</p>
            <div className="progress-bar-container">
                <span className="progress-bar-percentage font-size-normal"><b>{`${percentageRaised.toFixed(2)}%`}</b></span>
                <div className="progress-bar" style={{ width: `${percentageRaised}%` }}></div>
            </div>
            <p>1 $AVAE = <b>${currentPrice}</b></p>

            <div className={`buy-purchased-info ${isWalletConnected ? 'expanded' : ''}`}>
                <div className="buy-purchased">
                    <p>Your purchased $AVAE: <b className='orange'>{Math.floor(purchaseData.total) || 0}</b></p>
                    <div className="buy-purchased-more-container">
                        <p className="buy-purchased-more" onClick={handleTogglePurchaseDetails}>
                            {showPurchaseDetails ? "Hide full breakdown" : "Click to see full breakdown"}
                        </p>
                        <DownIcon className={`buy-purchased-icon ${showPurchaseDetails ? 'rotated' : ''}`} />
                    </div>
                    <div className={`buy-purchased-details ${showPurchaseDetails ? 'expanded' : ''}`}>
                        {/* <p className="buy-purchased-label">Purchased $AVAE on Sepolia: <b className='orange'>{purchaseData.sepolia}</b></p>
                            <p className="buy-purchased-label">Purchased $AVAE on Base Sepolia: <b className='orange'>{purchaseData.baseSepolia}</b></p> */}
                        <p className="buy-purchased-label">Purchased $AVAE on Ethereum: <b className='orange'>{Math.floor(purchaseData.ethereum)}</b></p>
                        <p className="buy-purchased-label">Purchased $AVAE on Optimism: <b className='orange'>{Math.floor(purchaseData.optimism)}</b></p>
                        <p className="buy-purchased-label">Purchased $AVAE on BNB Chain: <b className='orange'>{Math.floor(purchaseData.bnbChain)}</b></p>
                        <p className="buy-purchased-label">Purchased $AVAE on Polygon: <b className='orange'>{Math.floor(purchaseData.polygon)}</b></p>
                        <p className="buy-purchased-label">Purchased $AVAE on Base: <b className='orange'>{Math.floor(purchaseData.base)}</b></p>
                        <p className="buy-purchased-label">Purchased $AVAE on Arbitrum: <b className='orange'>{Math.floor(purchaseData.arbitrum)}</b></p>
                        <p className="buy-purchased-label">Purchased $AVAE on Avalanche: <b className='orange'>{Math.floor(purchaseData.avalanche)}</b></p>
                        <div className="buy-referral">
                            <p className="glow-text"><b>Invite a friend and earn 10%:</b></p>
                            {referralCode ? (
                                <div className="buy-referral-link">
                                    <a href={`https://avaethernity.com/?ref=${referralCode}`}>https://avaethernity.com/?ref={referralCode}</a>
                                    <div className="copy-button" onClick={handleCopyClick}>COPY</div>
                                    {showCopiedPopup && <div className="copied-popup">Copied to clipboard</div>}
                                </div>

                            ) : (
                                <span>Loading referral link...</span>
                            )}
                            <p className='buy-referral-earned'>$AVAE earned: <b className="orange">{referralAmount}</b></p>
                        </div>
                    </div>
                </div>


                <div className="buy-balance">
                    {isBalanceLoading ? (
                        <span>Loading balance...</span>
                    ) : (
                        <span>
                            Your {selectedToken.name} balance: <b className='orange'>{balanceData?.value ?
                                parseFloat(formatUnits(
                                    balanceData.value,
                                    (selectedChain === 56 && (selectedToken.name === 'USDT' || selectedToken.name === 'USDC')) ? 18 :
                                        (selectedToken.name === 'USDT' || selectedToken.name === 'USDC') ? 6 : 18
                                )).toFixed((selectedToken.name === 'ETH') ? 4 : 2) : '0.00'}</b>
                        </span>
                    )}
                </div>
            </div>

            <p className='glow-text buy-tokens-label font-size-normal'><b>BUY</b></p>
            <p className="buy-tokens-info">
                1. Tap the button below to open the page in your wallet mobile app. For other wallets proceed to step 2.
            </p>
            {/* <ConnectButton /> */}
            <div className="wallet-container">
            {deeplinkTrust && (
                <a href={deeplinkTrust} target="_blank" rel="noopener noreferrer" className="wallet-link">
                    <div className="wallet-button">
                            <img src={TrustWalletIcon} className="wallet-button-icon" alt="Trust Wallet" />
                        Trust Wallet
                    </div>
                </a>
            )}
            {deeplinkMetamask && (
                <a href={deeplinkMetamask} target="_blank" rel="noopener noreferrer" className="wallet-link">
                    <div className="wallet-button">
                        <img src={MetamaskIcon} className="wallet-button-icon" alt="Metamask" />
                        Metamask
                    </div>
                </a>
            )}
            </div>
            <p className="buy-tokens-info">
                2. Connect your wallet
            </p>
            <ConnectButton showBalance={false} chainStatus="none" />

            <div className="buy-checkout-form">
                <div className="buy-checkout-form-row">
                    <div className="buy-checkout-form-item">
                        <span>Select payment method:</span>
                        <CustomSelect
                            value={selectedChain}
                            label={chains.find(option => option.id === selectedChain)?.name}
                            onChange={handleChainChange}
                            initialOption={chains.find(option => option.id === selectedChain)?.name}
                            isNetwork={true}
                            selectedChain={selectedChain} // Add this line
                        />
                    </div>
                    <div className="buy-checkout-form-item">
                        <span>Select currency to spend:</span>
                        <CustomSelect
                            value={selectedToken.name}
                            label={selectedToken.name}
                            onChange={(option) => setSelectedToken(addressMap[selectedChain].tokens[option.label.toLowerCase()])}
                            initialOption={selectedToken.name}
                            selectedChain={selectedChain} // Add this line
                        />
                    </div>
                </div>
                <div className="buy-checkout-form-row">
                    <div className="buy-checkout-form-item">
                        <div className="buy-max-button-wrapper">
                            <span>{selectedToken.name} you pay:</span>
                            <div className="buy-max-button" onClick={handleMaxClick}>MAX</div>
                        </div>
                        <input
                            className="buy-checkout-amount-in"
                            name="value"
                            placeholder="0.001"
                            value={value}
                            onChange={handleInputChange} // Update this line to use the new handler
                            required
                        />
                        <img src={getTokenIcon(selectedToken.name, selectedChain)} className='buy-currency-icon' />
                    </div>
                    <div className="buy-checkout-form-item">
                        <span>$AVAE you get</span>
                        <div className="buy-checkout-amount-out">{tokensReceived}</div>
                        <img src={AvaIcon} className='buy-currency-icon' />
                    </div>
                </div>
                <p className="buy-amount-in-usd">USD: <b>${amountInUSD}</b></p>
                <div className={`stablecoin-warning ${showStablecoinWarning ? 'expanded' : ''}`}>
                    Please note: When paying with stablecoins (USDC, USDT, DAI), you need to approve two transactions—one for spending approval and one for the transfer.
                </div>
                <div className={`error-message ${errorMessage ? 'expanded' : ''}`}>
                    {errorMessage}
                </div>
                {balanceError && <div>Error fetching balance: {balanceError.message}</div>}
                <button className='buy-button' onClick={handleTransaction} disabled={isApprovePending || isContributePending || isEthPending || isCalculateTokensFetching}>
                    {isApprovePending || isContributePending || isEthPending ? 'Confirming...' : (isCalculateTokensFetching ? <span className="loader"></span> : 'Buy')}
                </button>
                {isProcessing && <div className="buy-transaction-success">Processing transaction...</div>}

                {isEthTxSuccess && (
                    <div className="buy-transaction-success">
                        Transaction Success: <a href={`${explorerMap[selectedChain]}${ethTransactionHash}`} target="_blank" rel="noopener noreferrer">View on Explorer</a>
                    </div>
                )}
                {/* {isApproveTxSuccess && (
                    <div className="buy-transaction-success">
                        Approval Success: <a href={`${explorerMap[selectedChain]}${approveHash}`} target="_blank" rel="noopener noreferrer">View on Explorer</a>
                    </div>
                )} */}
                {isContributeTxSuccess && (
                    <div className="buy-transaction-success">
                        Transaction Success: <a href={`${explorerMap[selectedChain]}${contributeHash}`} target="_blank" rel="noopener noreferrer">View on Explorer</a>
                    </div>
                )}
                {/* <button className="buy-button">
                    <span className="loader"></span>
                </button> */}
                {address ? (
                    <WertWidgetButton address={address} />
                ) : (
                    <p className="buy-card" onClick={() => setErrorMessage("Please connect your wallet")}>Not enough crypto? Buy with card now!</p>
                )}
                <p className="buy-disclaimer">
                    I understand that $AVAE is for entertainment purposes only. It carries a high risk with no guaranteed returns. I have done my own research, and by participating, I fully agree to the <a href="https://whitepaper.avaethernity.com/legal/terms-of-service" target="_blank">Terms of the presale</a>.
                </p>


            </div>
        </div>
    );
}

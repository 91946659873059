import React from 'react';
import AvaHead from './landing/ava-head.jpg';

const PlayButton = () => {
    const handleClick = () => {
        window.open('https://t.me/Ava_ETHERNITY_bot?start=r_80632d0c', '_blank');
    };

    return (
        <div className="play-button" onClick={handleClick} style={{ cursor: 'pointer' }}>
            <img src={AvaHead} alt="Ava Head" />
            <div className="play-button-text">
                <p>Play</p>
            </div>
        </div>
    );
};

export default PlayButton;

import React, { useEffect } from 'react';
import './App.css';
import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultConfig,
  RainbowKitProvider,
  ConnectButton,
  connectorsForWallets,
} from '@rainbow-me/rainbowkit';
import { WagmiConfig, http } from 'wagmi';
import {
  sepolia, baseSepolia, mainnet, polygon, optimism, arbitrum, base, bsc, avalanche } from 'wagmi/chains';
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import {
  rainbowWallet,
  metaMaskWallet,
  coinbaseWallet,
  walletConnectWallet,
  trustWallet,
  okxWallet,
} from '@rainbow-me/rainbowkit/wallets';
import ReactGA from "react-ga4";
import Home from './Home';



const queryClient = new QueryClient();

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [
        rainbowWallet,
        metaMaskWallet,
        coinbaseWallet,
        walletConnectWallet,
        trustWallet,
        okxWallet,
      ],
    },
  ],
  { appName: 'My RainbowKit App', projectId: '230b5609367bce2793546a0d2d3a04c2' },
);

const config = getDefaultConfig({
  appName: 'My RainbowKit App',
  projectId: '230b5609367bce2793546a0d2d3a04c2',
  connectors, // Replace with your WalletConnect project ID
  chains: [mainnet, polygon, optimism, arbitrum, base, bsc, avalanche],
  transports: {
    // [sepolia.id]: http('https://eth-sepolia.g.alchemy.com/v2/qIrFR-Jsp877rR-MIYcE3EfutHGjKh1W'), // replace with your provider
    // [baseSepolia.id]: http('https://base-sepolia.g.alchemy.com/v2/qIrFR-Jsp877rR-MIYcE3EfutHGjKh1W'), // replace with your provider
    [mainnet.id]: http('https://eth-mainnet.g.alchemy.com/v2/qIrFR-Jsp877rR-MIYcE3EfutHGjKh1W'), // replace with your provider
    [polygon.id]: http('https://polygon-mainnet.g.alchemy.com/v2/qIrFR-Jsp877rR-MIYcE3EfutHGjKh1W'), // replace with your provider
    [optimism.id]: http('https://opt-mainnet.g.alchemy.com/v2/qIrFR-Jsp877rR-MIYcE3EfutHGjKh1W'), // replace with your provider
    [arbitrum.id]: http('https://arb-mainnet.g.alchemy.com/v2/qIrFR-Jsp877rR-MIYcE3EfutHGjKh1W'), // replace with your provider
    [base.id]: http('https://base-mainnet.g.alchemy.com/v2/qIrFR-Jsp877rR-MIYcE3EfutHGjKh1W'), // replace with your provider
    [bsc.id]: http('https://bnb-mainnet.g.alchemy.com/v2/qIrFR-Jsp877rR-MIYcE3EfutHGjKh1W'), // replace with your provider
    [avalanche.id]: http('https://avax-mainnet.g.alchemy.com/v2/qIrFR-Jsp877rR-MIYcE3EfutHGjKh1W'), // replace with
  },
  ssr: true, // Set to true if using server-side rendering
});



function App() {

  useEffect(() => {
    ReactGA.initialize("G-5FV197K5QF");
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);


  return (
    <WagmiConfig config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider initialChain={mainnet} locale="en" connectors={connectors}>
          <Home />
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiConfig>
  );
}


export default App;

